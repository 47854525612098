// Déclaration statique de tout les fichiers JSON pour que le compilateur React les mettent dans le bundle final

import LangActivityAccountEntry from "./LangActivityAccountEntry.json";
import LangActivityReport from "./LangActivityReport.json";
import LangPayments from "./LangPayments.json";
import LangCharge from "./LangCharge.json";
import LangChargeHistory from "./LangChargeHistory.json";
import LangChargesToValidate from "./LangChargesToValidate.json";
import LangCommercialAgreement from "./LangCommercialAgreement.json";
import LangCompanyInformation from "./LangCompanyInformation.json";
import LangContact from "./LangContact.json";
import LangContractor from "./LangContractor.json";
import LangContractorFilter from "./LangContractorFilter.json";
import LangConventions from "./LangConventions.json";
import LangCountries from "./LangCountries.json";
import LangCurrencies from "./LangCurrencies.json";
import LangCustomer from "./LangCustomer.json";
import LangDashboard from "./LangDashboard.json";
import LangDocuments from "./LangDocuments.json";
import LangErrors from "./LangErrors.json";
import LangGED from "./LangGED.json";
import LangGeneral from "./LangGeneral.json";
import LangInvoiceRequest from "./LangInvoiceRequest.json";
import LangLogin from "./LangLogin.json";
import LangSalesCrMemo from "./LangSalesCrMemo.json";
import LangSalesInvoiced from "./LangSalesInvoiced.json";
import LangSettings from "./LangSettings.json";
import LangTraining from "./LangTraining.json";
import LangWage from "./LangWage.json";
import LangWorkContract from "./LangWorkContract.json";
import LangImportedWage from "./LangImportedWage.json";
import LangEntriesCorrections from "./LangEntriesCorrections.json";
import LangContractorRelationships from "./LangContractorRelations.json";

export default {
	langs: {
		FRA: "fr",
		ENU: "en",
	},
	resources: [
		LangActivityAccountEntry,
		LangActivityReport,
		LangPayments,
		LangCharge,
		LangChargeHistory,
		LangChargesToValidate,
		LangCommercialAgreement,
		LangCompanyInformation,
		LangContact,
		LangContractor,
		LangContractorFilter,
		LangConventions,
		LangCountries,
		LangCurrencies,
		LangCustomer,
		LangDashboard,
		LangDocuments,
		LangErrors,
		LangGED,
		LangGeneral,
		LangInvoiceRequest,
		LangLogin,
		LangSalesCrMemo,
		LangSalesInvoiced,
		LangSettings,
		LangTraining,
		LangWage,
		LangWorkContract,
		LangImportedWage,
		LangEntriesCorrections,
		LangContractorRelationships,
	],
};
