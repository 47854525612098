import React from "react";
import styled from "styled-components";
import { Grid, Hidden, List, ListItemText, ListItem as MuiListItem, Typography as MuiTypography } from "@material-ui/core";
// import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
	padding: ${(props) => props.theme.spacing(1) / 4}px ${(props) => props.theme.spacing(4)}px;
	background: ${(props) => props.theme.palette.common.white};
	position: relative;
`;

const ListItem = styled(MuiListItem)`
	display: inline-block;
	width: auto;
	padding-left: ${(props) => props.theme.spacing(2)}px;
	padding-right: ${(props) => props.theme.spacing(2)}px;

	&,
	&:hover,
	&:active {
		color: #000;
	}
`;

const Typography = styled(MuiTypography)`
	color: ${(props) => props.theme.footer.color};
`;

function Footer() {
	// const { t } = useTranslation();

	return (
		<Wrapper>
			<Grid container spacing={0}>
				<Hidden smDown>
					<Grid container item xs={12} md={6}>
						{/* <List>
							<ListItem component="a" href="#">
								<ListItemText disableTypography primary={<Typography variant="body2">{t("privacy-policy")}</Typography>} />
							</ListItem>
						</List> */}
					</Grid>
				</Hidden>
				<Grid container item xs={12} md={6} justify="flex-end">
					<List>
						<ListItem>
							<ListItemText
								disableTypography
								primary={<Typography variant="body2">{`© ${new Date().getFullYear()} - Europe Tech Group`}</Typography>}
							/>
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</Wrapper>
	);
}

export default Footer;
