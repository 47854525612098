import i18n from "i18next";
import ICU from "i18next-icu";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import imports from "./imports";
import { getDevMode } from "../api/localStorage";

const debugSpecificString = undefined;

// Agglomération de tous les fichiers de traductions en un objet utilisable par i18next
// !! Si des clés de traduction sont en doublon dans les imports, seule la dernière rencontré est conservée
const compileImports = (imports) => {
	const langs = imports.langs;
	let res = { en: { translation: {} }, fr: { translation: {} } };

	for (const key in imports.resources) {
		const dict = imports.resources[key];

		for (const translationKey in dict.Translations) {
			const elem = dict.Translations[translationKey];

			for (const langKey in langs) {
				const translation = elem[langKey];

				if (
					getDevMode() &&
					res[langs[langKey]].translation[translationKey] !== undefined &&
					res[langs[langKey]].translation[translationKey] !== translation
				) {
					console.log(`"${translationKey}"\nAvant :\n\t"${res[langs[langKey]].translation[translationKey]}"\nAprès :\n\t"${translation}"`);
				} else if (translationKey.toLowerCase().includes(debugSpecificString)) {
					console.log(`"${translationKey}"\nAvant :\n\t"${res[langs[langKey]].translation[translationKey]}"\nAprès :\n\t"${translation}"`);
				}

				res[langs[langKey]].translation[translationKey] = translation;
			}
		}
	}

	return res;
};

i18n
	.use(ICU)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: compileImports(imports),
		fallbackLng: "fr",
		debug: getDevMode(),
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
