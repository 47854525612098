/**
 * Convention de nommage :
 * Les icônes finissent par "Icon"
 *
 * Les composants ont le même nom que leurs fichiers
 * Les pages listes finissent par "List"
 * Les pages détails finissent par "Details"
 *
 * Les routes finissent par "Route" quand children est à null
 * ou undefined, "Routes" quand children est un tableau
 *
 * Quand cela est possible, définir la route d'une liste au pluriel,
 * et les details au singulier
 */

import React from "react";
import Async from "../components/Async";

// #region Import des icônes
import { User, Users } from "react-feather";
import {
	BusinessRounded as BusinessIcon,
	Dashboard as DashboardIcon,
	GroupAdd as GroupAddIcon,
	Work as WorkIcon,
	Contacts as ContactIcon,
	Description as DescriptionIcon,
	Receipt as ReceiptIcon,
	AccountBalance as AccountBalanceIcon,
	Send as SendIcon,
	Euro as EuroIcon,
	Assignment as AssignmentIcon,
	EventNote as EventNoteIcon,
	ViewList as ViewListIcon,
	Payment as PaymentsIcon,
} from "@material-ui/icons";
// #endregion Icônes

// #region Imports et définitions des routes du projet
// #region Composants de bases
// -- Imports --
const SignIn = Async(() => import("../pages/auth/SignIn"));
const ResetPassword = Async(() => import("../pages/auth/ResetPassword"));
const Page404 = Async(() => import("../pages/auth/Page404"));
const Page500 = Async(() => import("../pages/auth/Page500"));
const PageNetworkError = Async(() => import("../pages/auth/PageNetworkError"));
const Dashboard = Async(() => import("../pages/pages/Dashboard"));
// -- Définitions --
const authRoutes = {
	id: "Auth",
	path: "/auth",
	icon: <Users />,
	children: [
		{
			path: "/auth/login",
			name: "Connexion",
			component: SignIn,
		},
		{
			path: "/auth/reset-password",
			name: "Reset Password",
			component: ResetPassword,
		},
		{
			path: "/404",
			name: "Page non trouvée",
			component: Page404,
		},
		{
			path: "/500",
			name: "Erreur interne",
			component: Page500,
		},
		{
			path: "/networkerror",
			name: "Erreur réseau",
			component: PageNetworkError,
		},
	],
};
const dashboardRoute = {
	id: "dashboard",
	path: "/",
	icon: <DashboardIcon />,
	component: Dashboard,
	children: null,
	isHome: true,
};
// #endregion Composants de bases

// #region Profil
// -- Imports --
const Profile = Async(() => import("../pages/pages/Profile"));
// -- Définitions --
const profileRoutes = {
	id: "profile",
	path: "/profil",
	icon: <User />,
	component: Profile,
	children: null,
};
// #endregion Profil

// #region Informations société
// -- Imports --
const CompanyInformation = Async(() => import("../pages/pages/CompanyInformation"));
// -- Définitions --
const companyInformationRoutes = {
	id: "company-information",
	path: "/company-information",
	icon: <User />,
	component: CompanyInformation,
	children: null,
};
// #endregion Informations société

// #region Mon équipe
// -- Imports --
const Team = Async(() => import("../pages/pages/Team"));
const TeamContactDetails = Async(() => import("../pages/pages/TeamContactDetails"));
// -- Définitions --
const teamListRoute = {
	id: "team",
	path: "/team",
	icon: <User />,
	component: Team,
	children: null,
};
const teamContactDetailsRoute = {
	id: "teamContactDetails",
	path: "/team/:no",
	icon: <User />,
	component: TeamContactDetails,
	children: null,
};
// #endregion Mon équipe

// #region Portés
// -- Imports --
const ContractorsList = Async(() => import("../pages/pages/ContractorsList"));
const ContractorDetails = Async(() => import("../pages/pages/ContractorDetails"));
// const ContractorFilter = async(() => import("../pages/pages/ContractorFilter"));
// -- Définitions --
const contractorsListRoute = {
	id: "contractors",
	path: "/contractors",
	icon: <GroupAddIcon />,
	component: ContractorsList,
	// children: [
	//   {
	//     path: "/contractors",
	//     name: "contractors-list",
	//     component: ContractorsList,
	//   },
	//   {
	//     path: "/contractors/filter",
	//     name: "contractors-filters",
	//     component: ContractorFilter,
	//   },
	// ],
};
const contractorDetailsRoutes = {
	id: "contractorDetails",
	path: "/contractor/:No",
	icon: <User />,
	component: ContractorDetails,
	children: null,
};
// #endregion Portés

// #region Conventions de portage
// -- Imports --
const ConventionsList = Async(() => import("../pages/pages/ConventionsList"));
const ConventionDetails = Async(() => import("../pages/pages/ConventionDetails"));
// -- Définitions --
const conventionsListRoute = {
	id: "conventions",
	path: "/conventions",
	icon: <AssignmentIcon />,
	component: ConventionsList,
};
const conventionDetailsRoute = {
	id: "convention-details",
	path: "/convention/:No",
	icon: <User />,
	component: ConventionDetails,
	children: null,
};
// #endregion Conventions de portage

// #region Contrat de Travail
// -- Imports --
const WorkContractsList = Async(() => import("../pages/pages/WorkContractList"));
const WorkContractDetails = Async(() => import("../pages/pages/WorkContractDetails"));
// -- Définitions --
const workContractListRoute = {
	id: "work-contracts",
	path: "/work-contracts",
	icon: <WorkIcon />,
	component: WorkContractsList,
};
const workContractDetailsRoute = {
	id: "work-contract-details",
	path: "/work-contract/:No",
	component: WorkContractDetails,
};
// #endregion Contrat de Travail

// #region Contrats Commerciaux
// -- Imports --
const CommercialAgreementList = Async(() => import("../pages/pages/CommercialAgreementList"));
const CommercialAgreementDetails = Async(() => import("../pages/pages/CommercialAgreementDetails"));
// -- Définitions --
const commercialAgreementsListRoutes = {
	id: "commercial-agreements",
	path: "/commercial-agreements",
	icon: <DescriptionIcon />,
	component: CommercialAgreementList,
	children: [
		{
			path: "/commercial-agreements",
			component: CommercialAgreementList,
			name: "ca-all",
		},
		{
			path: "/commercial-agreements/status/initialized",
			component: CommercialAgreementList,
			name: "ca-initialized",
		},
		{
			path: "/commercial-agreements/status/active",
			component: CommercialAgreementList,
			name: "ca-active",
		},
		{
			path: "/commercial-agreements/status/ended",
			component: CommercialAgreementList,
			name: "ca-ended",
		},
	],
};
const commercialAgreementsDetailsRoute = {
	id: "commercial-agreements-details",
	path: "/commercial-agreements/:No",
	component: CommercialAgreementDetails,
};
// #endregion Contrats Commerciaux

// #region Clients
// -- Imports --
const CustomerList = Async(() => import("../pages/pages/CustomerList"));
const CustomerDetails = Async(() => import("../pages/pages/CustomerDetails"));
// -- Définitions --
const customerListRoute = {
	id: "customerList",
	path: "/customers",
	icon: <BusinessIcon />,
	component: CustomerList,
	children: null,
};
const customerDetailsRoute = {
	id: "customerDetails",
	path: "/customer/:No",
	icon: <BusinessIcon />,
	component: CustomerDetails,
	children: null,
};
// #endregion Clients

// #region Factures
// -- Imports --
const SalesInvoiceList = Async(() => import("../pages/pages/SalesInvoiceList"));
const SalesInvoiceDetails = Async(() => import("../pages/pages/SalesInvoiceDetails"));
// -- Définitions --
const salesInvoiceListRoute = {
	id: "sales-invoices",
	path: "/sales-invoices",
	icon: <ReceiptIcon />,
	component: SalesInvoiceList,
};
const salesInvoiceDetails = {
	id: "sales-invoices",
	path: "/sales-invoice/:No",
	icon: <ReceiptIcon />,
	component: SalesInvoiceDetails,
	children: null,
};
// #endregion Factures

// #region Avoirs
// -- Imports --
const SalesCrMemoList = Async(() => import("../pages/pages/SalesCrMemoList"));
const SalesCrMemoDetails = Async(() => import("../pages/pages/SalesCrMemoDetails"));
// -- Définitions --
const salesCrMemoListRoute = {
	id: "sales-cr-memos",
	path: "/sales-cr-memos",
	icon: <ReceiptIcon />,
	component: SalesCrMemoList,
};
const salesCrMemoDetailsRoute = {
	id: "sales-cr-memos",
	path: "/sales-cr-memo/:No",
	icon: <ReceiptIcon />,
	component: SalesCrMemoDetails,
	children: null,
};
// #endregion Avoirs

// #region Contacts
// -- Imports --
const ContactList = Async(() => import("../pages/pages/ContactsList"));
const ContactDetails = Async(() => import("../pages/pages/ContactDetails"));
// -- Définitions --
const contactListRoute = {
	id: "contact",
	path: "/contacts",
	icon: <ContactIcon />,
	component: ContactList,
	children: null,
};
const contactDetailsRoute = {
	id: "contat-details",
	path: "/contact/:No",
	component: ContactDetails,
	children: null,
};
// #endregion Contacts

// #region Demande de facturation
// -- Imports --
const InvoiceRequestList = Async(() => import("../pages/pages/InvoiceRequestList"));
const InvoiceRequestDetails = Async(() => import("../pages/pages/InvoiceRequestDetails"));
// -- Définitions --
const invoiceRequestListRoute = {
	id: "invoice-requests",
	path: "/invoice-requests",
	icon: <SendIcon />,
	component: InvoiceRequestList,
	children: [
		{
			path: "/invoice-requests/status/to-validate", // À valider
			component: InvoiceRequestList,
			name: "ir-to-validate",
		},
		{
			path: "/invoice-requests/status/pending", // En cours
			component: InvoiceRequestList,
			name: "ir-pending",
		},
		{
			path: "/invoice-requests/status/invoiced", // Facturé
			component: InvoiceRequestList,
			name: "ir-invoiced",
		},
	],
};
const invoiceRequestDetailsRoute = {
	id: "invoice-requests",
	path: "/invoice-request/:No",
	icon: <SendIcon />,
	component: InvoiceRequestDetails,
	children: null,
};
// #endregion Demande de facturation

// #region Rapports d'activité
// -- Imports --
const ActivityReportsList = Async(() => import("../pages/pages/ActivityReportsList"));
const ActivityReportDetails = Async(() => import("../pages/pages/ActivityReportDetails"));
// -- Définitions --
const activityReportsListRoute = {
	id: "activity-reports",
	path: "/activity-reports",
	icon: <EventNoteIcon />,
	component: ActivityReportsList,
	children: null,
};
const activityReportDetailsRoute = {
	id: "activity-report",
	path: "/activity-report/:ActivityNo",
	icon: <EventNoteIcon />,
	component: ActivityReportDetails,
	children: null,
};
// #endregion Frais

// #region Frais
// -- Imports --
// const ChargesList = Async(() => import("../pages/pages/ChargesList"));
const ChargesToValidateList = Async(() => import("../pages/pages/ChargesToValidateList"));
const ChargesToValidateDetails = Async(() => import("../pages/pages/ChargesToValidateDetails"));
const ChargesHistoryList = Async(() => import("../pages/pages/ChargesHistoryList"));
const ChargesHistoryDetails = Async(() => import("../pages/pages/ChargesHistoryDetails"));
// -- Définitions --
const chargesListRoute = {
	id: "charges",
	path: "/charges",
	icon: <EuroIcon />,
	component: ChargesToValidateList,
	children: [
		{
			path: "/charges-to-validate/status/pending", // En cours
			component: ChargesToValidateList,
			name: "pending-charges",
		},
		{
			path: "/charges-to-validate/status/initialized", // À valider
			component: ChargesToValidateList,
			name: "charges-to-validate",
		},
		{
			path: "/charges-history", // Frais enregistrés
			component: ChargesHistoryList,
			name: "charges-history",
		},
	],
};
const chargesToValidateDetailsRoute = {
	id: "charges-to-validate",
	path: "/charges-to-validate/:ChargesNo",
	icon: <EuroIcon />,
	component: ChargesToValidateDetails,
	children: null,
};
const chargeHistoryDetailsRoute = {
	id: "charges-history-details",
	path: "/charge-history/:ChargesNo",
	icon: <EuroIcon />,
	component: ChargesHistoryDetails,
	children: null,
};
// #endregion Frais

// #region Compte D'activité
// -- Imports --
const ActivityAccountList = Async(() => import("../pages/pages/ActivityAccountList"));
const ActivityAccountListFiltered = Async(() => import("../pages/pages/ActivityAccountListFiltered"));
// -- Définitions --
const activityAccountListRoute = {
	id: "activity-account",
	path: "/activity-account",
	icon: <AccountBalanceIcon />,
	component: ActivityAccountList,
};
const activityAccountListFilteredRoute = {
	id: "activity-account",
	path: "/activity-account/:ContractorNo",
	icon: <AccountBalanceIcon />,
	component: ActivityAccountListFiltered,
};
// #endregion Compte D'activité

// #region Payes
// -- Imports --
const WagesList = Async(() => import("../pages/pages/WagesList"));
const WageDetails = Async(() => import("../pages/pages/WageDetails"));
// -- Définitions --
const wageListRoute = {
	id: "wage",
	path: "/wages",
	icon: <AccountBalanceIcon />,
	component: WagesList,
};
const wageDetailsRoute = {
	id: "wage",
	path: "/wage/:No",
	component: WageDetails,
};
// #endregion

// #region Import de paye
// -- Imports --
const ImportedWageList = Async(() => import("../pages/pages/WageImportList"));
// -- Définitions --
const importedWageListRoute = {
	id: "imported-wage",
	path: "/imported-wage",
	icon: <AccountBalanceIcon />,
	component: ImportedWageList,
};
// #endregion Import de paye

// #region Préparation de paies
// -- Imports --
const WagePreparationList = Async(() => import("../pages/pages/WagePreparationList"));
const WagePreparationDetails = Async(() => import("../pages/pages/WagePreparationDetails"));
// -- Définitions --
const wagePreparationListRoute = {
	id: "wage-preparation",
	icon: <PaymentsIcon />,
	path: "/wage-preparation",
	component: WagePreparationList,
};
const wagePreparationDetailsRoute = {
	id: "wage-preparation-details",
	path: "/wage-preparation/:No",
	component: WagePreparationDetails,
};
// #endregion Préparation de paies

// #region Règlements
// -- Imports --
const PaymentsList = Async(() => import("../pages/pages/PaymentsList"));
// -- Définitions --
const paymentsListRoute = {
	id: "payments",
	path: "/payments",
	icon: <ViewListIcon />,
	component: PaymentsList,
};
// #endregion Règlements
// #endregion Imports et définitions des routes du projet

// #region Corrections compte d'activité
// -- Imports --
const EntriesCorrectionsList = Async(() => import("../pages/pages/EntriesCorrectionsList"));
const entriesCorrectionsListRoute = {
	id: "entries-corrections",
	path: "/entries-correction",
	icon: <ViewListIcon />,
	component: EntriesCorrectionsList,
};
// #endregion Corrections compte d'activité

// #region Export des routes
// Routes de bases nécessaires au projet
export const auth = [authRoutes];

// Toutes les autres pages utilisées au cours du projet
// L'ordre n'as pas d'importance ici, mais il est toujours bon d'être organisé
export const dashboard = [
	dashboardRoute,
	profileRoutes,
	companyInformationRoutes,
	// -- Client --
	customerListRoute,
	customerDetailsRoute,
	// -- Porté --
	contractorsListRoute,
	contractorDetailsRoutes,
	// -- Convention de portage --
	conventionsListRoute,
	conventionDetailsRoute,
	// -- Contrat de Travail --
	workContractListRoute,
	workContractDetailsRoute,
	// -- Contrat Commercial --
	commercialAgreementsListRoutes,
	commercialAgreementsDetailsRoute,
	// -- Demande de facturation --
	invoiceRequestListRoute,
	invoiceRequestDetailsRoute,
	// -- Rapports d'activité --
	activityReportsListRoute,
	activityReportDetailsRoute,
	// -- Frais --
	chargesListRoute,
	chargesToValidateDetailsRoute, // Frais à valider
	chargeHistoryDetailsRoute, // Frais enregistrés
	// -- Contacts --
	contactListRoute,
	contactDetailsRoute,
	// -- Factures --
	salesInvoiceListRoute,
	salesInvoiceDetails,
	// -- Avoirs --
	salesCrMemoListRoute,
	salesCrMemoDetailsRoute,
	// -- Équipe --
	teamListRoute,
	teamContactDetailsRoute,
	// -- Compte d'activité --
	activityAccountListRoute,
	activityAccountListFilteredRoute,
	// -- Corrections compte d'activité --
	entriesCorrectionsListRoute,
	// -- Règlements --
	paymentsListRoute,
	// -- Payes --
	wageListRoute,
	wageDetailsRoute,
	wagePreparationListRoute,
	wagePreparationDetailsRoute,
	importedWageListRoute,
];

// Définition du menu (sidebar)
// L'ordre ici défini la structure du menu, c'est donc très important !
export default [
	dashboardRoute, // TDB
	contractorsListRoute, // Portés
	conventionsListRoute, // Convention de portage
	workContractListRoute, // Contrats de Travail
	customerListRoute, // Clients
	contactListRoute, // Contacts
	commercialAgreementsListRoutes, // Contrats Commerciaux
	invoiceRequestListRoute, // Demandes de facturations
	activityReportsListRoute, // Rapports d'activité
	chargesListRoute, // Frais
	salesInvoiceListRoute, // Factures enregistrés
	salesCrMemoListRoute, // Avoirs enregistrés
	paymentsListRoute, // Règlements
	activityAccountListRoute, // Compte d'activité
	wagePreparationListRoute, // Préparation de la paye
	wageListRoute, // Payes
	importedWageListRoute, // Import de la paye
	entriesCorrectionsListRoute, // Correction compte d'activité
];
// #endregion Export des routes
