import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import useFetch, { fetcher } from "../api/fetcher";
import { connect, useDispatch } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link as RouterLink, withRouter, useHistory, Link } from "react-router-dom";

import { removeToken } from "../api";

import {
	Button,
	Grid,
	Hidden,
	Menu,
	MenuItem,
	AppBar as MuiAppBar,
	IconButton as MuiIconButton,
	Toolbar,
	Typography,
	CircularProgress,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { Menu as MenuIcon, AccountCircle, Delete as DeleteIcon, WarningOutlined } from "@material-ui/icons";
import { spacing } from "@material-ui/system";

import i18n from "../i18n";
import { clearContractorFilter, setContractorFilter } from "../redux/actions/contractorFilterActions";
import { useCustomSnackbar } from "../pages/components/Snackbar";
import { kebabCase } from "lodash";
// import { ContractorSearch } from "../pages/components";
import { ContractorSearchForFilter } from "../pages/components/ContractorSearch";

const AppBar = styled(MuiAppBar)`
	background: ${(props) => props.theme.header.background};
	color: ${(props) => props.theme.header.color};
	box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
	margin: 0 ${(props) => props.theme.spacing(2)}px;

	svg {
		width: 22px;
		height: 22px;
	}
`;

const Flag = styled.img`
	border-radius: 50%;
	width: 22px;
	height: 22px;
`;

const UserButton = styled(Button)`
	font-size: 15px;
	height: 40px;
`;

const AccountIcon = styled(AccountCircle)`
	height: 28px;
	width: 28px;
`;

const WarningOutlinedIcon = styled(WarningOutlined)(spacing);

function LanguageMenu() {
	const [anchorMenu, setAnchorMenu] = useState(null);

	const { t } = useTranslation();

	const toggleMenu = (event) => {
		setAnchorMenu(event.currentTarget);
	};

	const setLanguage = (lng) => {
		i18n.changeLanguage(lng);
		closeMenu();
	};

	const closeMenu = () => {
		setAnchorMenu(null);
	};

	return (
		<React.Fragment>
			<IconButton aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined} aria-haspopup="true" onClick={toggleMenu} color="inherit">
				<Flag src={t("lng-flag")} alt={t("lng-name")} />
			</IconButton>
			<Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
				<MenuItem onClick={() => setLanguage("en")}>English</MenuItem>
				<MenuItem onClick={() => setLanguage("fr")}>Français</MenuItem>
			</Menu>
		</React.Fragment>
	);
}

const UserMenu = withRouter(() => {
	const [anchorMenu, setAnchorMenu] = useState(null);
	const [user] = useFetch("GET", "/login/identity");

	const history = useHistory();

	const { t } = useTranslation();

	const toggleMenu = (event) => {
		setAnchorMenu(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorMenu(null);
	};

	const logout = () => {
		removeToken();
		history.push("/auth/login");
	};

	var username = user?.AccFirstName || user?.AccLogin;
	if (!username) {
		username = <Skeleton variant="text" width={40} />;
	}

	return (
		<React.Fragment>
			<UserButton color="inherit" onClick={toggleMenu} startIcon={<AccountIcon />} height={30}>
				{username}
			</UserButton>
			<Menu open={Boolean(anchorMenu)} anchorEl={anchorMenu} onClose={closeMenu}>
				<MenuItem component={RouterLink} to="/company-information">
					{t("company-information")}
				</MenuItem>
				{/* <MenuItem component="a" target="_blank" href="/profil">
					{t("profile")}
				</MenuItem> */}
				{/* <MenuItem component={RouterLink} onClick={() => history.push("/team")}>
					{t("team")}
				</MenuItem> */}
				<MenuItem onClick={logout}>{t("disconnect")}</MenuItem>
			</Menu>
		</React.Fragment>
	);
});

const Header = ({ onDrawerToggle, contractorFilter }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const snackbar = useCustomSnackbar();

	// #region Annulation des requêtes fetch lors de la fin de vie du composant
	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Annulation des requêtes fetch lors de la fin de vie du composant

	const [openContractorSearch, setOpenContractorSearch] = useState(false);

	// #region Gestion de l'affichage du filtre porté
	const [fetchedContractorFilter] = useFetch("GET", "/profile/filter");
	const [currentFilter, setCurrentFilter] = useState();

	useEffect(() => {
		if (fetchedContractorFilter?.ContractorFilter) {
			setCurrentFilter(fetchedContractorFilter.ContractorFilter);
			dispatch(setContractorFilter(fetchedContractorFilter.ContractorFilter));
		} else if (fetchedContractorFilter?.ContractorFilter === null || fetchedContractorFilter instanceof Error) {
			setCurrentFilter(null);
			dispatch(setContractorFilter(null));
		}
	}, [fetchedContractorFilter, dispatch]);

	// Le filtre porté tel que stocké dans redux
	useEffect(() => {
		setCurrentFilter(contractorFilter);
	}, [contractorFilter]);
	// #endregion Gestion de l'affichage du filtre porté

	// #region Suppression du filtre porté
	const [loading, setLoading] = useState(false);
	function deleteContractorFilter() {
		if (loading) return;

		setLoading(true);
		fetcher(signal, "PUT", "/profile/contractor-filter", {
			AWAContractorFilter: "",
		})
			.then((res) => {
				if (res?.ErrorMessage !== "") {
					// Bien faire attention qu'il existe une traduction lié au message d'erreur !
					snackbar.showError(`${t("request-modify-filter-error")} ${t(kebabCase(res?.ErrorMessage))}`);
				} else if (res?.ModifiedUserMenu?.AWAContractorFilter === "") {
					dispatch(clearContractorFilter());
					setCurrentFilter(null);
					snackbar.showSuccess(t("request-modify-filter-success"));
				}
			})
			.catch(console.error)
			.finally(() => setLoading(false));
	}
	// #endregion Suppression du filtre porté

	return (
		<React.Fragment>
			<AppBar position="sticky" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center">
						{/* Bouton de menu */}
						<Hidden mdUp>
							<Grid item>
								<IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
									<MenuIcon />
								</IconButton>
							</Grid>
						</Hidden>

						{/* Affichage du filtre porté */}
						<Grid item xs container alignItems="center">
							{(() => {
								if (currentFilter === undefined) {
									return (
										<Grid item>
											<Skeleton animation="wave" width="200px" height="30px" />
										</Grid>
									);
								} else if (currentFilter === null) {
									return (
										<>
											<Grid item>
												<WarningOutlinedIcon mr={2} />
											</Grid>
											<Grid item>
												<Link
													to="#"
													onClick={() => {
														setOpenContractorSearch(true);
													}}
													style={{ textDecoration: "none" }}
												>
													<Typography variant="h6" style={{ color: "#777" }}>
														{t("no-contractor-filter")}
													</Typography>
												</Link>
											</Grid>
											<ContractorSearchForFilter open={openContractorSearch} setOpen={setOpenContractorSearch} />
										</>
									);
								} else {
									return (
										<>
											<Grid item>
												<Link
													to="#"
													style={{ textDecoration: "none" }}
													onClick={() => {
														setOpenContractorSearch(true);
													}}
												>
													<Typography variant="h6" style={{ color: "#777" }}>
														{t("contractor-filter")} : {currentFilter?.No} - {currentFilter?.FirstName} {currentFilter?.LastName}
													</Typography>
												</Link>
											</Grid>
											<Grid item>
												<IconButton size="small" onClick={deleteContractorFilter} disabled={loading}>
													{!loading ? <DeleteIcon /> : <CircularProgress size="10" />}
												</IconButton>
											</Grid>
											<ContractorSearchForFilter open={openContractorSearch} setOpen={setOpenContractorSearch} />
										</>
									);
								}
							})()}
						</Grid>

						{/* Menu de profil & langage */}
						<Grid item>
							<UserMenu />
							<LanguageMenu />
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		contractorFilter: state.contractorFilterReducer.contractorFilter,
	};
};

export default connect(mapStateToProps)(withTheme(Header));
