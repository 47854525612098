import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import styled from "styled-components";

const ButtonNoText = styled(Button)`
	& .MuiButton-startIcon,
	& .MuiButton-endIcon {
		margin: 0 auto;
	}
`;

export const LoadingButton = React.forwardRef((props, ref) => {
	// On ne récupère que ce dont on à besoins
	const { loading, ...rest } = props;
	let { iconPosition, ...buttonProps } = rest;
	let endIcon, startIcon;

	if (iconPosition === "end") {
		endIcon = loading ? <CircularProgress color="inherit" size={20} /> : null;
	} else {
		startIcon = loading ? <CircularProgress color="inherit" size={20} /> : null;
	}

	return (
		<Button ref={ref} {...buttonProps} startIcon={startIcon} endIcon={endIcon}>
			{props.children}
		</Button>
	);
});

export const LoadingButtonWithIcon = React.forwardRef((props, ref) => {
	// On ne récupère que ce dont on à besoins
	const { loading, icon, ...rest } = props;
	let { iconPosition, startIcon, endIcon, ...buttonProps } = rest;

	if (iconPosition === "end") {
		endIcon = loading ? <CircularProgress color="inherit" size={20} /> : icon;
	} else {
		startIcon = loading ? <CircularProgress color="inherit" size={20} /> : icon;
	}

	return (
		<Button ref={ref} {...buttonProps} startIcon={startIcon} endIcon={endIcon}>
			{props.children}
		</Button>
	);
});

export const LoadingIconButton = React.forwardRef((props, ref) => {
	// On ne récupère que ce dont on à besoins
	const { loading, icon, ...rest } = props;

	let loadingIcon = loading ? <CircularProgress color="inherit" size={24} /> : icon;

	return (
		<IconButton {...rest} ref={ref}>
			{loadingIcon}
		</IconButton>
	);
});

export const ButtonWithIcon = React.forwardRef((props, ref) => {
	// On ne récupère que ce dont on à besoins
	const { icon, ...rest } = props;
	let { iconPosition, startIcon, endIcon, ...buttonProps } = rest;

	if (iconPosition === "end") {
		endIcon = icon;
	} else {
		startIcon = icon;
	}

	return <ButtonNoText ref={ref} {...buttonProps} startIcon={startIcon} endIcon={endIcon} />;
});

LoadingButton.propTypes = {
	loading: PropTypes.bool.isRequired,
	iconPosition: PropTypes.oneOf(["start", "end"]),
	...Button.propTypes,
};
LoadingButton.defaultProps = {
	iconPosition: "start",
};

LoadingButtonWithIcon.propTypes = {
	loading: PropTypes.bool.isRequired,
	icon: PropTypes.node.isRequired,
	iconPosition: PropTypes.oneOf(["start", "end"]),
	...Button.propTypes,
};
LoadingButtonWithIcon.defaultProps = {
	iconPosition: "start",
};

ButtonWithIcon.propTypes = {
	icon: PropTypes.node.isRequired,
	iconPosition: PropTypes.oneOf(["start", "end"]),
	...Button.propTypes,
};
ButtonWithIcon.defaultProps = {
	iconPosition: "start",
};

LoadingIconButton.propTypes = {
	loading: PropTypes.bool.isRequired,
	icon: PropTypes.node.isRequired,
	...IconButton.propTypes,
};
