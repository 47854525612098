import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, Paper, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { fetcher, fetcherNoTreatment } from "../../api/fetcher";
// import {useSnackbar} from "material-ui-snackbar-provider";
import { Skeleton } from "@material-ui/lab";
import { SearchOutlined } from "@material-ui/icons";
import { useCustomSnackbar } from "./Snackbar";
import { kebabCase } from "lodash";
import { useDispatch } from "react-redux";
import { setContractorFilter } from "../../redux/actions/contractorFilterActions";
import { LoadingButton } from "./Buttons";

const ContractorSearch = ({ open, setOpen, multiple, cb }) => {
	// const snackbar = useSnackbar();
	const { t } = useTranslation();

	// #region Annulation des requêtes fetch lors de la fin de vie du composant
	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Annulation des requêtes fetch lors de la fin de vie du composant

	const [contractors, setContractors] = useState(); // Liste complète des portés
	const [selected, setSelected] = useState([]); // Liste des portés séléctionnés
	const [search, setSearch] = useState(""); // Le contenu du champs de recherche
	const [searchFilteredContractors, setSearchFilteredContractors] = useState(); // Liste des portés qui correspondent au filtre

	useEffect(() => {
		// On ne fait la requête que l'orsque le client ouvre la pop-up, vive l'optimisation !
		if (open && !contractors) {
			fetcher(signal, "GET", "/contractor").then(({ Contractors }) => setContractors(Contractors));
		}
	}, [open, contractors, signal]);

	// Reset de la recherche quand la liste des portés change
	// Permet aussi d'initialiser la liste quand le fetch à fini
	useEffect(() => {
		setSearchFilteredContractors(contractors);
		setSearch("");
	}, [contractors]);

	function handleSearchChange(event) {
		// Remplissage du champ de recherche
		let value = event.target.value || "";
		setSearch(value);

		// Filtrage de la liste selon la recherche
		setSearchFilteredContractors(
			contractors.filter((contractor) => {
				// On ne filtre que ces propriétées
				const { No, FirstName, LastName } = contractor;

				return `${No} ${FirstName} ${LastName}`.toLowerCase().includes(value?.trim()?.toLowerCase());
			})
		);
	}

	return (
		<Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
			<DialogTitle>{t("contractor-search")}</DialogTitle>

			<DialogContent dividers>
				<TextField
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchOutlined />
							</InputAdornment>
						),
					}}
					fullWidth
					variant="outlined"
					onChange={(event) => handleSearchChange(event)}
					value={search}
					label={t("dialog-type-to-search")}
				/>
			</DialogContent>

			<DialogContent style={{ height: 400 }} dividers>
				<Paper style={{ overflow: "auto" }}>
					{!Array.isArray(searchFilteredContractors) ? (
						<>
							<Skeleton height={80} />
							<Skeleton height={80} />
							<Skeleton height={80} />
						</>
					) : (
						searchFilteredContractors.map((elm) => (
							<Paper
								style={{ padding: 5, borderLeft: selected.includes(elm.No) ? "2px solid black" : null }}
								key={elm.No}
								onClick={() => {
									if (!multiple && selected.includes(elm.No)) {
										let tmp = selected;
										tmp.filter((predicate) => predicate.No !== elm.No);
										setSelected([tmp]);
									} else if (multiple) {
										setSelected([...selected, elm.No]);
									} else {
										setSelected([elm.No]);
									}
								}}
							>
								<p
									style={{
										cursor: "pointer",
										fontWeight: selected.includes(elm.No) ? "bold" : "normal",
									}}
								>
									{elm.No} - {elm.FirstName} {elm.LastName}
								</p>
							</Paper>
						))
					)}
				</Paper>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" onClick={() => setOpen(false)}>
					{t("cancel")}
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						cb(selected);
						setOpen(false);
					}}
					disabled={selected.length === 0}
					color="secondary"
				>
					{t("select")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ContractorSearch;

export const ContractorSearchForFilter = ({ open, setOpen, multiple }) => {
	const snackbar = useCustomSnackbar();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	// #region Annulation des requêtes fetch lors de la fin de vie du composant
	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Annulation des requêtes fetch lors de la fin de vie du composant

	const [contractors, setContractors] = useState(); // Liste complète des portés
	const [selected, setSelected] = useState([]); // Liste des portés séléctionnés
	const [search, setSearch] = useState(""); // Le contenu du champs de recherche
	const [isLoading, setIsLoading] = useState(false);
	const [selectedNo, setSelectedNo] = useState();
	const [searchFilteredContractors, setSearchFilteredContractors] = useState(); // Liste des portés qui correspondent au filtre

	useEffect(() => {
		// On ne fait la requête que l'orsque le client ouvre la pop-up, vive l'optimisation !
		if (open && !contractors) {
			fetcher(signal, "GET", "/contractor").then(({ Contractors }) => setContractors(Contractors));
		}
	}, [open, contractors, signal]);

	// Reset de la recherche quand la liste des portés change
	// Permet aussi d'initialiser la liste quand le fetch à fini
	useEffect(() => {
		setSearchFilteredContractors(contractors);
		setSearch("");
	}, [contractors]);

	function handleSearchChange(event) {
		// Remplissage du champ de recherche
		let value = event.target.value || "";
		setSearch(value);

		// Filtrage de la liste selon la recherche
		setSearchFilteredContractors(
			contractors?.filter((contractor) => {
				// On ne filtre que ces propriétées
				const { No, FirstName, LastName } = contractor;

				return `${No} ${FirstName} ${LastName}`.toLowerCase().includes(value?.trim()?.toLowerCase());
			})
		);
	}

	const handleContractorSelect = () => {
		setIsLoading(true);
		fetcherNoTreatment("PUT", "/profile/contractor-filter", {
			AWAContractorFilter: selected[0].No,
		})
			.then((res) => {
				if (res?.ErrorMessage !== "") {
					snackbar.showError(`${t("request-modify-filter-error")} ${t(kebabCase(res?.errorMessage))}`, "error-during-request");
				} else if (res?.ModifiedUserMenu?.AWAContractorFilter !== "") {
					dispatch(
						setContractorFilter({
							No: selected[0].No,
							FirstName: selected[0].FirstName,
							LastName: selected[0].LastName,
						})
					);
					snackbar.showSuccess(t("request-modify-filter-success"));
				}
			})
			.catch(console.error)
			.finally(() => {
				setIsLoading(false);
				setOpen(false);
			});
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
			<DialogTitle>{t("contractor-search")}</DialogTitle>

			<DialogContent dividers>
				<TextField
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchOutlined />
							</InputAdornment>
						),
					}}
					fullWidth
					variant="outlined"
					onChange={(event) => handleSearchChange(event)}
					value={search}
					label={t("dialog-type-to-search")}
				/>
			</DialogContent>

			<DialogContent style={{ height: 400 }} dividers>
				<Paper style={{ overflow: "auto" }}>
					{!Array.isArray(searchFilteredContractors) ? (
						<>
							<Skeleton height={80} />
							<Skeleton height={80} />
							<Skeleton height={80} />
						</>
					) : (
						searchFilteredContractors.map((elm) => (
							<Paper
								style={{ padding: 5 }}
								key={elm.No}
								onClick={() => {
									if (!multiple && selected.includes(elm.No)) {
										let tmp = selected;
										tmp.filter((predicate) => predicate.No !== elm.No);
										setSelected([tmp]);
									} else if (multiple) {
										setSelected([...selected, elm.No]);
									} else {
										setSelected([
											{
												No: elm.No,
												FirstName: elm.FirstName,
												LastName: elm.LastName,
											},
										]);
										setSelectedNo(elm.No);
									}
								}}
							>
								<p
									style={{
										cursor: "pointer",
										fontWeight: selectedNo === elm.No ? "bold" : "normal",
									}}
								>
									{elm.No} - {elm.FirstName} {elm.LastName}
								</p>
							</Paper>
						))
					)}
				</Paper>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" onClick={() => setOpen(false)}>
					{t("cancel")}
				</Button>
				<LoadingButton
					variant="contained"
					loading={isLoading}
					onClick={() => handleContractorSelect()}
					disabled={selected.length === 0}
					color="secondary"
				>
					{t("select")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
