//#region Filtre de l'équipe pour les tickets
const TICKET_TEAM_FILTER = "TICKET_TEAM_FILTER";

export const getTicketTeamFilterFromStorage = () => {
	try {
		return JSON.parse(localStorage.getItem(TICKET_TEAM_FILTER) ?? "[]");
	} catch (e) {
		return [];
	}
};

export const setTicketTeamFilterToStorage = (ticketTeamFilter) => {
	ticketTeamFilter = JSON.stringify(ticketTeamFilter);
	localStorage.setItem(TICKET_TEAM_FILTER, ticketTeamFilter);
	return ticketTeamFilter;
};

export const removeTicketTeamFilter = () => localStorage.removeItem(TICKET_TEAM_FILTER);
//#endregion

//#region Configuration des tableaux avancés
const ENHANCED_TABLE_SETTINGS = "ENHANCED_TABLE_SETTINGS";

function filterProps({ id, active, ...rest }) {
	return { id, active, ...rest };
}

export const mergeHeadCells = (arr1, arr2) => {
	// On ne sauvegarde que certaines propriétés
	arr1 = arr1.map(filterProps);
	arr2 = arr2.map(filterProps);

	let ids = new Set(arr1.map((d) => d.id));
	return [...arr1, ...arr2.filter((d) => !ids.has(d.id))];
};

export const getEnhancedTableSettings = (id) => {
	try {
		let settings = JSON.parse(localStorage.getItem(ENHANCED_TABLE_SETTINGS) ?? "{}");

		return !!id ? settings[id] : settings;
	} catch (e) {
		return {};
	}
};

export const setEnhancedTableSettings = (enhancedTableSettings) => {
	enhancedTableSettings = JSON.stringify(enhancedTableSettings);
	localStorage.setItem(ENHANCED_TABLE_SETTINGS, enhancedTableSettings);
};

export const setEnhancedTableSettingsById = (id, newSettings) => {
	const settings = getEnhancedTableSettings();
	if (!settings[id]) {
		settings[id] = newSettings;
	} else {
		settings[id] = { ...settings[id], ...newSettings };
	}

	setEnhancedTableSettings(settings);
};
//#endregion

//#region Masquage du message d'aide à la saisie du Suipro
const SHOW_ACTIVITY_HELP_TOOLTIP = "SHOW_ACTIVITY_HELP_TOOLTIP";

export const getShowActivityHelpTooltip = () => {
	return localStorage.getItem(SHOW_ACTIVITY_HELP_TOOLTIP) !== "false" ? true : false;
};

export const setShowActivityHelpTooltip = (showActivityHelpTooltip) => {
	localStorage.setItem(SHOW_ACTIVITY_HELP_TOOLTIP, showActivityHelpTooltip);
};
//#endregion

//#region Mode développeur
const DEV_MODE = "DEV_MODE";

export const getDevMode = () => {
	return localStorage.getItem(DEV_MODE) === "true";
};

export const setDevMode = (showActivityHelpTooltip) => {
	localStorage.setItem(DEV_MODE, showActivityHelpTooltip);
};

export const removeDevMode = () => localStorage.removeItem(DEV_MODE);

document.addEventListener("keydown", (event) => {
	if (event.ctrlKey && event.altKey && event.key.toLowerCase() === "d") {
		let newDevMode = !getDevMode();
		setDevMode(newDevMode);
		console.info(`DevMode ${newDevMode ? "activé" : "désactivé"}`);
	}
});
//#endregion
