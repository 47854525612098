import { useEffect, useState } from "react";
import { useCustomSnackbar } from "../pages/components/Snackbar";
import { useTranslation } from "react-i18next";

const ErrorsApi = () => {
	const [errorApi, setErrorApi] = useState(undefined);
	const snackbar = useCustomSnackbar();
	const { t } = useTranslation();

	document.addEventListener("api_error", (e) => setErrorApi(e));

	useEffect(() => {
		if (errorApi) {
			// console.error(errorApi);
			switch (errorApi.detail.status) {
				case 500:
				default:
					snackbar.showError(t("error-internal"));
					break;
			}
			setErrorApi(undefined);
		}
	}, [errorApi, snackbar, t]);

	return null;
};

export default ErrorsApi;
