// L'URL de l'API peut être modifiée dans le fichier public/config.js
export const API_URL = window.config.apiUrl;

const KEY = "API_TOKEN";

export const setToken = (token) => localStorage.setItem(KEY, token);

export const getToken = () => localStorage.getItem(KEY);

export const isLoggedIn = () => getToken() !== "" && getToken() !== null && getToken() !== undefined;

export const removeToken = () => localStorage.removeItem(KEY);
